// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// AdminLTE
@import '~admin-lte/dist/css/adminlte.css';

@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

.user {
    @extend %fa-icon;
    @extend .fas;

    &:before {
        content: fa-content($fa-var-user);
    }
}

.twitter {
    @extend %fa-icon;
    @extend .fab;

    &:before {
        content: fa-content($fa-var-twitter);
    }
}

.sidebar-dark-primary {
    background: $dark;
}

